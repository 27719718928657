import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import GlobalLoader from '../Componants/GlobalLoader'

function Terms() {
    const [isLoading, setIsLoading] = useState(true)
    const [termsOfUse, setTermsOfUse] = useState({})
    const baseURL = process.env.REACT_APP_BASE_URL;
    useEffect(() => {
        fetch(` ${baseURL}/page?where={"name":"terms"}`)
            .then(response => response.json())
            .then(data => {
                setTermsOfUse(data?.data?.pages[0])
                setIsLoading(false)
            }
            );
    }, []);
    return (
        <div>
            {isLoading && <GlobalLoader />}
            <div className="wrap_cpg">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-12 col-sm-12 col-lg-12 pt-5">
                            <div className="bak_button">
                                <span><Link to="/" style={{ textDecoration: 'none', color: 'inherit' }}>Go Back to Home</Link></span>
                            </div>
                            <div className="pg_inside text-left">
                                <h2>Terms Of Use</h2>
                                <div className="pg_con">
                                    <div dangerouslySetInnerHTML={{ __html: termsOfUse.content }} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Terms