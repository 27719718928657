import React, { useState,useRef } from 'react';
import Slider from 'react-slick';
function SliderService() {
    const [nav1, setNav1] = useState();
    const [nav2, setNav2] = useState();
  
//     const next = () => {
//      slider.current.slickNext();
//    }
//     const previous = () => {
//      slider.current.slickPrev();
//    }
    var settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 1,        
        prevArrow:
        <span className="prev_btn hc_btn"> 
            <img src="assets/images/left-arrow.svg" alt="" />
        </span>
        ,
        nextArrow: 
        <span className="nxt_btn hc_btn">
            <img src="assets/images/right-arrow.svg" alt="" />
        </span>
        ,
        responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
                infinite: true,
                dots: false
              }
            },
            {
              breakpoint: 768,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,
              }
            }
        ]
    };
    return (
        <div>
          <div className="slider-serv-row">
            <div className="col_photos col_phts_left">
                <Slider asNavFor={nav2} ref={(slider1) => setNav1(slider1)} {...settings}>
                    <div>
                        <div className="img_item">
                            <img src="assets/images/ds1.png" alt="" />
                            <p>Electrical</p>
                        </div>
                    </div>
                    <div>
                        <div className="img_item">
                            <img src="assets/images/ds2.png" alt="" />
                            <p>Carpentry</p>
                        </div>
                    </div>
                    <div>
                        <div className="img_item">
                            <img src="assets/images/ds3.png" alt="" />
                            <p>Home Cleaning</p>
                        </div>
                    </div>
                    <div>
                        <div className="img_item">
                            <img src="assets/images/ds4.png" alt="" />
                            <p>HVAC</p>
                        </div>
                    </div>
                    <div>
                        <div className="img_item">
                            <img src="assets/images/ds5.png" alt="" />
                            <p>Landscaping</p>
                        </div>
                    </div>
                    <div>
                        <div className="img_item">
                            <img src="assets/images/ds1.png" alt="" />
                            <p>Electrical</p>
                        </div>
                    </div>
                    <div>
                        <div className="img_item">
                            <img src="assets/images/ds2.png" alt="" />
                            <p>Carpentry</p>
                        </div>
                    </div>
                    <div>
                        <div className="img_item">
                            <img src="assets/images/ds3.png" alt="" />
                            <p>Home Cleaning</p>
                        </div>
                    </div>
                    <div>
                        <div className="img_item">
                            <img src="assets/images/ds4.png" alt="" />
                            <p>HVAC</p>
                        </div>
                    </div>
                    <div>
                        <div className="img_item">
                            <img src="assets/images/ds5.png" alt="" />
                            <p>Landscaping</p>
                        </div>
                    </div>
                    {/* <div>
                        <div className="img_item">
                            <img src="assets/images/ds3.png" alt="" />
                            <p>Home Cleaning</p>
                        </div>
                    </div> */}
                </Slider>
            </div>
          </div>
        </div>
      );
}

export default SliderService