import React from 'react'
import NavBar from '../Componants/NavBar'
import Footer from '../Componants/Footer'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; // Import the CSS for styling

function Layout({ children }) {
    return (
        <>
            <ToastContainer /> {/* Place this at the root level of your app */}
            <NavBar />
            {children}
            <Footer />
        </>
    )
}

export default Layout
