import React from 'react'
import {  Image   } from 'react-bootstrap';
import { Link } from 'react-router-dom';
function NavBar() {
  return (    
    <>
    <div className={"menuNav"}>   
    <div className="container">     
      <div className="nav_item">
        <div className="nav_left">
            <Link to={"/"}>
              <Image className='img-fluid' src="/assets/images/logo.png"/>
            </Link>
        </div>
      </div>
    </div>
    </div>
    </>
  )
}

export default NavBar