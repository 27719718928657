import React from "react";
import { Container, Image } from "react-bootstrap";
import SliderService from "../Componants/SliderService";
import { Link } from "react-router-dom";

function Home() {
  return (
    <>
      <Container fluid className="p-0">
        <div className="banner text-center text-md-start">
          <div className="container banner-container">
            <div className="row m-0 banner-row">
              <div className="col-12 col-lg-7">
                <div className="banne_left">
                  <div className="bl_top">
                    <h2>
                      <span>A simple solution</span>{" "}
                      <span>to home improvement</span>
                    </h2>
                    <h1 className="mb-2">The Labor Gator </h1>
                    <p>
                      A fast, simple, and reliable way to hire Contractors at
                      low cost with little to no lead time. We source the
                      expertise you seek.
                    </p>
                  </div>
                  <div className="bl_bottom">
                    <div className="row services_list_row m-0">
                      <div className="col-lg-4">
                        <div className="services_col text-center">
                          <div className="sc_top">
                            <div className="sc_icon">
                              <img
                                src="assets/images/mobile-phone.svg"
                                alt=""
                              />
                            </div>
                          </div>
                          <div className="sc_body">
                            {/* <h3>POST A LEAD</h3> */}
                            <p>Instant contractor quotes to your mobile app</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="services_col text-center">
                          <div className="sc_top">
                            <div className="sc_icon">
                              <img src="assets/images/dollar.svg" alt="" />
                            </div>
                          </div>
                          <div className="sc_body">
                            {/* <h3>POST A LEAD</h3> */}
                            <p>Earn gift cards with our rewards program</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="services_col text-center">
                          <div className="sc_top">
                            <div className="sc_icon">
                              <img src="assets/images/calendar.svg" alt="" />
                            </div>
                          </div>
                          <div className="sc_body">
                            {/* <h3>POST A LEAD</h3> */}
                            <p>
                              Post your time and date for the service required
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-5 p-0">
                <div className="banner_right">
                  <Image
                    className="img-fluid banner_img"
                    src="/assets/images/bnr-rt.png"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>

      <section className="services">
        <div className="container">
          <div className="sec_hd">
            <span>Our Services</span>
          </div>
          <div className="service_list">
            <SliderService />
          </div>
        </div>
      </section>
      <section className="cta-lga">
        <div className="container">
          <div className="row m-0 align-items-center">
            <div className="col-lg-7">
              <div className="cta_left">
                <div className="cta_left_head">
                  <h2>
                    <span>Go Get</span>
                    <span> The Labor Gator App</span>
                  </h2>
                  <p>
                    Get The Labor Gator Customer App and The Labor Gator
                    Contractor App
                  </p>
                </div>
                <div className="cta_left_body col-md-8">
                  <div className="clb_btns">
                    <div className="clb_btn">
                      <Link to="https://play.google.com/store/apps/details?id=com.laborgatorcustomer" target="_blank">
                        <img src="assets/images/gp.png" alt="" />
                      </Link>
                    </div>
                    <div className="clb_btn">
                      <Link to="https://apps.apple.com/us/app/the-labor-gator-client/id6468027099" target="_blank">
                        <img src="assets/images/as.png" alt="" />
                      </Link>
                    </div>
                  </div>

                  {/* <div className="dowload_btns">
                                    <Link to="">
                                        <p>
                                            <span>Download Customer App</span> 
                                            <span><img src="assets/images/long-right-arrow.svg" alt="" /></span>
                                        </p>
                                    </Link>
                                    <Link to="">
                                        <p>
                                            <span>Download Contractor App</span> 
                                            <span><img src="assets/images/long-right-arrow.svg" alt="" /></span>
                                        </p>
                                    </Link>
                                </div> */}
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="cta_right">
                <img src="assets/images/cta-img.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Home;
