import { useForm, Controller } from 'react-hook-form';
import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';
import GlobalLoader from './GlobalLoader';

function ContactPopup({ handleClose, show }) {
  const [isLoading, setIsLoading] = useState(false)
  const { handleSubmit, control, formState: { errors } } = useForm();
  const baseURL = process.env.REACT_APP_BASE_URL;
  const onSubmit = async (data) => {
    setIsLoading(true)
    try {
      const response = await fetch(`${baseURL}/contact_us`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
      });

      if (!response.ok) {
        setIsLoading(false)
        toast.error('An error occurred!', {
          position: 'top-right', // Position the toast message
          autoClose: 3000,       // Auto close the toast after 3 seconds (milliseconds)
          hideProgressBar: false, // Show a progress bar
          closeOnClick: true,     // Close the toast on click
          pauseOnHover: true,     // Pause the timer when hovering
        });
        throw new Error('Network response was not ok');
      }
      toast.success('Your message was sent successfully!', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
      });
      handleClose()
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <>
      {isLoading && <GlobalLoader />}
      <Modal show={show} onHide={handleClose} className='contactPopup'>
        <Modal.Header closeButton>
          <Modal.Title>Contact Us</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Controller
                name="name"
                control={control}
                defaultValue=""
                rules={{
                  required: 'Name is required',
                  pattern: {
                    value: /\S/, // Regular expression for non-empty input
                    message: 'Name is required',
                  },
                }}
                render={({ field }) => (
                  <Form.Control
                  type="text"
                  placeholder="Name"
                  autoFocus
                  {...field}
                  />
                )}
              />
              {errors.name && <p className='form-error-msg'>{errors.name.message}</p>}
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Controller
                name="email"
                control={control}
                defaultValue=""
                rules={{
                  required: 'Email is required',
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, // Email regex pattern
                    message: 'Invalid email address',
                  },
                }}
                render={({ field }) => (
                  <Form.Control
                    type="text"
                    placeholder="Email Address"
                    {...field}
                  />
                )}
              />
              {errors.email && <p className='form-error-msg'>{errors.email.message}</p>}
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Controller
                name="phone"
                control={control}
                defaultValue=""
                rules={{
                  required: 'Phone is required',
                  pattern: {
                    value: /^[0-9]{10}$/, // Regular expression for non-empty input
                    message: 'Phone should be valid',
                  },
                  minLength: {
                    value: 10,
                    message: 'Phone should be exactly 10 digits',
                  },
                  maxLength: {
                    value: 10,
                    message: 'Phone should be exactly 10 digits',
                  },
                }}
                render={({ field }) => (
                  <Form.Control
                    type="text"
                    placeholder="Phone"
                    {...field}
                  />
                )}
              />
              {errors.phone && <p className='form-error-msg'>{errors.phone.message}</p>}
            </Form.Group>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Controller
                name="message"
                control={control}
                defaultValue=""
                rules={{
                  required: 'Message is required',
                  pattern: {
                    value: /\S/, // Regular expression for non-empty input
                    message: 'Message is required',
                  },
                }}
                render={({ field }) => (
                  <Form.Control
                    as="textarea"
                    rows={3}
                    placeholder="How can we help with your business"
                    {...field}
                  />
                )}
              />
              {errors.message && <p className='form-error-msg'>{errors.message.message}</p>}
            </Form.Group>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Button variant="secondary" type='submit' className='w-100 py-2'>
                SEND MESSAGE
              </Button>
            </Form.Group>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ContactPopup;