import React from 'react'
import { Circles } from 'react-loader-spinner';

function GlobalLoader() {
  return (
    <Circles
      height="80"
      width="80"
      color="#4fa94d"
      ariaLabel="circles-loading"
      wrapperStyle={{}}
      wrapperClass="loader"
      visible={true}
    />
  );
}

export default GlobalLoader
