import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";
import './App.css';
import './index.scss'
import 'bootstrap/dist/css/bootstrap.css';
import './css/animate.css';
import Home from './Pages/Home';
import Terms from "./Pages/Terms";
import Privacy from "./Pages/Privacy";
import ContractorPrivacy from "./Pages/ContractorPrivacy ";
import ContractorTerms from "./Pages/ContractorTerms";

import Layout from "./Pages/Layout";
function App() {
  return (
    <div className="App">
      <BrowserRouter>
      <Layout>
        <Routes>
          <Route path="/" element={<Home/>}>
          </Route>
          <Route path="/terms-of-use" element={<Terms/>}>
          </Route>
          <Route path="/privacy-policy" element={<Privacy/>}>
          </Route>
          <Route path="/contractor-privacy-policy" element={<ContractorPrivacy/>}>
          </Route>
          <Route path="/contractor-terms-of-use" element={<ContractorTerms/>}>
          </Route>
        </Routes>
        </Layout>
      </BrowserRouter>
    </div>
  );
}

export default App;
