import React,{useState} from 'react';
import { Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ContactPopup from './ContactPopup';

function Footer() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const currentYear=new Date().getFullYear()
    return (        
        <div>
            {
                show &&
                <ContactPopup handleClose={handleClose} show={show}/>
            }
            <footer>
                <Container>
                    <div className='fc_row'>
                        <div className='fc_left'>
                            <ul>
                                <li><Link to="/terms-of-use" target="_blank">Terms Of Use</Link></li>
                                <li><Link to="/privacy-policy" target="_blank">Privacy Policy</Link></li>
                                <li><span onClick={handleShow}>Contact Us</span></li>
                            </ul>
                        </div>
                        <div className='fc_right'>
                            <ul>
                                <li className='text-white'>
                                    <span>© {currentYear} The Labor Gator | All Rights Reserved</span>
                                </li>
                            </ul>
                        </div>
                    </div>

                </Container>
            </footer>
        </div>
    )
}

export default Footer